body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.disable {
  pointer-events: none;
  opacity: 0.6;
}

.allhistory {
  text-align: center;
  cursor: pointer;
  /* height: 50px; */
  align-items: center;
  --bs-bg-opacity: 1;
  background: #0d6efd;

}

.allhistory p {
  /* width: 100%; */
  padding: 10px;
  color: #fff;
  margin-bottom: 0px;
}

.allhistory .active {
  background-color: rgb(100, 149, 237);
}

.roomadd {
  padding: 20px;
}

.roomadd input {
  width: 100%;
  margin: 6px;
}

.GallaryImg {
  width: 60px;
}

.GallaryImg img {
  width: 100%;
}

.yourSelectt {
  margin-top: 6px !important;
  height: 12px;
  width: 12px;
  margin: auto;
  border-radius: 50%;
  background: #008000;

}

.yourSelecta {
  margin-top: 6px !important;
  height: 12px;
  width: 12px;
  margin: auto;
  border-radius: 50%;
  background: #0000ff;
}

.yourSelectB {
  margin-top: 6px !important;
  height: 12px;
  width: 12px;
  margin: auto;
  border-radius: 50%;
  background: #ff0000;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}

.yourSelectB a:hover {
  color: #ffffff !important;

}

.dataTables_paginate.paging_simple_numbers.category {
  float: right;
}

.dataTables_paginate.paging_simple_numbers.category li:nth-child(1) {
  background: transparent !important;
}

.dataTables_paginate.paging_simple_numbers.category li.selected {
  color: #fff !important;
  background: #40189D !important;
}

.dataTables_paginate.paging_simple_numbers.category li {
  border: 0 !important;
  padding: 7px 16px;
  border: 0px solid #40189D !important;
  background: rgba(64, 24, 157, 0.3) !important;
  border-radius: 5rem;
  color: #40189D !important;
  font-size: 16px;
  margin: 0;
  display: inline-block;
}


.dataTables_paginate.paging_simple_numbers.category li:last-child {
  background: transparent !important;
}

li {
  list-style: none;
}

.roomAdd {
  padding: 5px 15px;
  background: #0000ff;
  color: #ffffff;
  display: block;
  margin: auto;
  width: 80px;
}

.preWin {
  /* background: #008000 !important; */
  color: #008000 !important;
}

.preloss {
  /* background: #ff0000 !important; */
  color: #ff0000 !important;
}

.tieCount {
  display: block;
  float: right;
  margin-bottom: 1px;
  padding: 24px;
}

.baharCount {
  display: block;
  float: left;
  margin-bottom: 1px;
  padding: 24px;
}

.tieCount p {
  color: #ffffff
}

.baharCount p {
  color: #ffffff
}

.demoNone {
  display: none;
}

.dataTables_length.category {
  float: left;
}

.dataTables_filter.category {
  float: right;
}

.login_button.newQue {
  float: right;
  font-size: 37px;
  margin-top: -20px;
}

.roomT .card-title {
  float: left;
}

.roomaddBack {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
}

.roomaddBackB {
  cursor: pointer;
}


.dashboardContaint {
  padding: 30px;
}

.shadow {
  margin-left:3px ;
}
/* 
@media (min-width: 576px) {
  .dashboardCard {
    padding-left: 30px;
  }

} */

.tableResponsiv{
  overflow: auto;
}

.roomDImg{
  width: 100%;
}
.roomDImg img{
  width: 100%;
}

.dashboardCard{
  background: #ba792b;
  padding: 20px;
  margin: 30px;
  text-align: center;
  color: #ffffff;
  border-radius: 10px;
}

.dashboardCard a{
  color: #ffffff;
}
.dashboardCard a:hover{
  color: #ffffff;
}


a.sc-eCImPb.Goxgq {
  text-decoration: none;
  color: gray;
  width: 100%;
  padding: 1rem 0px 0rem 1rem;
  cursor: pointer;
  display: flex;
}

.categorySelect select {
  width: 100%;
  margin-top: 5px;
  height: 37px;
}

@media only screen and (max-width: 634px){
  .dashboardCard {
    padding: 20px;
    margin: 10px;
    margin-left: 30px;
  }

}
@media only screen and (max-width: 576px){
  .dashboardCard {
    padding: 20px;
    margin: 10px;
    margin-left: 30px;
  }

}